import { Link } from 'gatsby';
import React from 'react';

const NotFoundPage: React.FC = () => (
  <div>
    <h1>Oops! 404</h1>
    <h3>You just hit a route that doesn&#39;t exist.</h3>
    <Link to="/">Go Home</Link>
  </div>
);

export default NotFoundPage;
